import { observer } from 'mobx-react'
import { FC, useContext } from 'react'
import {
  BackButton,
  Content,
  DataColumn,
  DataGrid,
  SearchInput,
  SearchInputContainer,
  StyledPage,
  Title,
  TitleIconContainer,
  TopContent,
} from './TransactionDetailsPage.styles'
import { TopMenu } from '@/components/TopMenu/TopMenu'
import { MobileHeader } from '@/components'
import { TitleIcon } from './Icons/TitleIcon'

import { SearchIcon } from './Icons/SearchIcon'
import { ProviderData } from './Components/ProviderData'
import { BasicInfo } from './Components/BasicInfo/BasicInfo'
import { StoreContext } from '@/components/App'

export const TransactionDetailsPage: FC = observer(() => {
  const store = useContext(StoreContext)
  const { transactionRaw, providers } = store.TransactionDetailsState

  const oddProviders = providers.filter((_, i) => i % 2 === 0)
  const evenProviders = providers.filter((_, i) => i % 2 !== 0)

  return (
    <StyledPage>
      <Content>
        <TopContent>
          <BackButton to={'/transactions'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M9.20032 14.5497C9.41185 14.7612 9.75481 14.7612 9.96635 14.5497C10.1779 14.3381 10.1779 13.9952 9.96635 13.7836L6.72437 10.5417L14.5833 10.5417C14.8824 10.5417 15.125 10.2991 15.125 9.99999C15.125 9.70084 14.8824 9.45832 14.5833 9.45832L6.72437 9.45832L9.96635 6.21634C10.1779 6.00481 10.1779 5.66184 9.96635 5.45031C9.75482 5.23877 9.41185 5.23877 9.20032 5.45031L5.03365 9.61697C4.82212 9.82851 4.82212 10.1715 5.03365 10.383L9.20032 14.5497Z"
                fill="currentColor"
              />
            </svg>
            Back
          </BackButton>

          <TitleIconContainer>
            <TitleIcon />
          </TitleIconContainer>
          <Title>
            Transaction:
            <span> {transactionRaw.transactionId}</span>
          </Title>
        </TopContent>
        <SearchInputContainer>
          <SearchInput placeholder="Search" />
          <SearchIcon />
        </SearchInputContainer>

        <BasicInfo transaction={transactionRaw} providers={providers} />

        <DataGrid>
          <DataColumn>
            {evenProviders.map((provider, v) => (
              <ProviderData key={v} provider={provider} />
            ))}
          </DataColumn>
          <DataColumn>
            {oddProviders.map((provider, v) => (
              <ProviderData key={v} provider={provider} />
            ))}
          </DataColumn>
        </DataGrid>
      </Content>
      <MobileHeader />
      <TopMenu />
    </StyledPage>
  )
})

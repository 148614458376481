import { createContext, FC, useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { observer } from 'mobx-react'

import { Helmet } from './Helmet'
import { ContentRouter } from './ContentRouter'

import { rootStore } from '@store/Root.store'

import { GlobalStyles } from '@styles/App.styles'
import { themes } from '@/themes/themes'
import { SelectOption } from '@constants/SelectOption'
import { toSelectOptions, tenantsSort } from '@helpers/selectHelpers'
import { SelectActiveTenantModalBody, TenantDropdownWrapper, TenantSelector } from '@/styles/generic.styles'
import { SelectInput } from '@components/generic'
import AxiosErrorHandler from '@/axios/AxiosErrorHandler'
import { Toasts } from './Toasts/Toasts'
import { Popup } from './Popup/Popup'
import { useDarkMode } from '@hooks/useDarkmode'

export const StoreContext = createContext(rootStore)

export const App: FC = observer(() => {
  const DEV_MODE = true
  const { isDarkMode } = useDarkMode()

  const theme = isDarkMode ? themes.darkTheme : themes.lightTheme

  const store = useContext(StoreContext)
  const { currentUserId, isLogged, shouldLogout } = store.Auth
  const { tenants, selectedTenant } = store.AppState

  useEffect(() => {
    if (isLogged && !shouldLogout && !DEV_MODE) {
      void store.AppState.fetchTenants()
      store.Auth.initKeepSessionAliveInterval()
      return
    }

    return () => {
      store.Auth.disposeKeepSessionAliveInterval()
    }
  }, [isLogged, shouldLogout])

  useEffect(() => {
    if (!DEV_MODE) {
      void store.Auth.checkAuth()
    }
  }, [currentUserId, isLogged, shouldLogout])

  const renderTenantSwitcher = () => {
    if (tenants && tenants.length) {
      return (
        <TenantSelector>
          <span>clientId:</span>
          <TenantDropdownWrapper>
            <SelectInput
              options={toSelectOptions(tenants.sort(tenantsSort))}
              value={{
                value: selectedTenant || '',
                label: selectedTenant || 'select tenant',
              }}
              onChange={(e: SelectOption) => {
                void store.AppState.setSelectedTenant(e.value)
              }}
              {...theme.selectInput}
            />
          </TenantDropdownWrapper>
        </TenantSelector>
      )
    } else {
      return <></>
    }
  }

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet title="Signicat Onboarding Dashboard" description="Onboarding Dashboard" themeColor="#320822" />

      <ThemeProvider theme={theme}>
        <AxiosErrorHandler>
          <GlobalStyles />
          <ContentRouter />
          <Toasts />
          {!DEV_MODE && (
            <Popup
              visibility={
                (!localStorage.getItem('selectedTenant')?.length ||
                  selectedTenant === 'null' ||
                  selectedTenant === 'undefined' ||
                  !selectedTenant?.length) &&
                isLogged &&
                !shouldLogout
              }
              {...theme.popup}
              title="Select Tenant"
              width="430px"
              closeIconHidden={true}
            >
              <SelectActiveTenantModalBody>
                Please set an active Tenant:
                {renderTenantSwitcher()}
              </SelectActiveTenantModalBody>
            </Popup>
          )}
        </AxiosErrorHandler>
      </ThemeProvider>
    </StoreContext.Provider>
  )
})

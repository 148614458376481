import styled, { css } from 'styled-components'

interface ContainerInterface {
  borderWidth?: string
  color?: string
  colorDisabled?: string
  borderColor?: string
  borderRadius?: string
  backgroundColor?: string
  borderColorFocus?: string
  colorFocus?: string
  backgroundColorFocus?: string
  colorSelected?: string
  backgroundColorSelected?: string
  menuMarginTop?: string
  menuBorderWidth?: string
  indicatorColorFocus?: string
  indicatorColorHover?: string
}

export const Container = styled.div<ContainerInterface>`
  display: inline-block;
  width: 100%;

  .select-input {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    &__value-container {
      padding: 10px 14px;
    }

    &__single-value {
      color: ${(props) => (props.color ? props.color : 'hsl(0, 0%, 20%)')};

      &--is-disabled {
        color: ${(props) => (props.colorDisabled ? props.colorDisabled : 'pink')};
      }
    }

    &__input-container {
      color: ${(props) => (props.color ? props.color : '#000')};
    }

    &__control {
      border-width: ${(props) => (props.borderWidth ? props.borderWidth : '100%')};
      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
      color: ${(props) => (props.color ? props.color : '#000')};
      border-radius: ${(props) => (props.borderRadius ? props.borderRadius : '8px')};
      min-width: 160px;
      border-color: ${(props) => (props.borderColor ? props.borderColor : 'hsl(0, 0%, 80%)')}!important;

      /* #TODO: fix it */
      /* :not(&--is-focused) {
        box-shadow: none;
      } */

      .select-input__indicator {
        color: ${(props) => (props.borderColor ? props.borderColor : 'hsl(0, 0%, 80%)')};

        :hover {
          color: ${(props) => (props.indicatorColorHover ? props.indicatorColorHover : 'hsl(0, 0%, 80%)')};
        }
      }

      .select-input__indicator-separator {
        background-color: ${(props) => (props.borderColor ? props.borderColor : 'hsl(0, 0%, 80%)')}!important;
      }

      :hover {
        cursor: pointer;
        border-color: ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')}!important;
        box-shadow: 0px 0px 0px 1px ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')};
      }

      &--is-focused {
        border-color: ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')};
        box-shadow: 0px 0px 0px 1px ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')};

        .select-input__indicator {
          color: ${(props) => (props.indicatorColorFocus ? props.indicatorColorFocus : 'hsl(0, 0%, 80%)')};
        }

        .select-input__indicator-separator {
          background-color: ${(props) =>
            props.borderColorFocus ? props.borderColorFocus : 'hsl(0, 0%, 80%)'}!important;
        }
      }

      &--menu-is-open {
        background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
        border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius} ${props.borderRadius}` : '8px 8px')}
          0px 0px;
        border-color: ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')}!important;

        :hover {
          background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#fff')};
          border-color: ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')};
        }
      }
    }

    &__menu {
      border-radius: 0px 0px
        ${(props) => (props.borderRadius ? `${props.borderRadius} ${props.borderRadius}` : '8px 8px')};
      border-color: ${(props) => (props.borderColorFocus ? props.borderColorFocus : '#000')};
      background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#FFF')};

      ${({ menuMarginTop }) =>
        menuMarginTop &&
        css`
          margin-top: ${menuMarginTop};
        `}

      ${({ menuBorderWidth }) =>
        menuBorderWidth &&
        css`
          border-width: ${menuBorderWidth};
          border-style: solid;
          box-shadow: none;
        `}
    }

    &__menu-list {
      padding-top: 0px;
      padding-bottom: 0px;
      border-radius: 0px 0px
        ${(props) => (props.borderRadius ? `${props.borderRadius} ${props.borderRadius}` : '8px 8px')};
    }

    &__option {
      padding: 10px 16px;
      color: ${(props) => (props.color ? props.color : '#000')};
      cursor: pointer;

      &--is-focused,
      &:focus,
      &:active {
        background-color: ${(props) => (props.backgroundColorFocus ? props.backgroundColorFocus : 'pink')}!important;
        color: ${(props) => (props.colorFocus ? props.colorFocus : '#000')}!important;
      }

      &--is-selected {
        background-color: ${(props) =>
          props.backgroundColorSelected ? props.backgroundColorSelected : '#000'}!important;
        color: ${(props) => (props.colorSelected ? props.colorSelected : '#FFF')}!important;
      }
    }

    &__indicator-separator {
      width: ${(props) => (props.borderWidth ? props.borderWidth : '100%')};
    }
  }
`
